// ENUMS
export enum EnumCollection {
  Papyrus = "papyrus",
  Commits = "commits",
  Documents = "documents",
  DocumentsMeta = "documentsMeta",
  Widgets = "widgets",
  Users = "users"
}

export enum EnumLoginProviders {
  Github = "github",
  Google = "google"
}

export enum EnumTokens {
  background = "#5E4D64",
  black = "#3D274D",
  border = "#9987A7",
  borderRadius = "4px",
  link = "#ABCCFF",
  primary = "#9988A7",
  sanSerif = '"Lato", sans-serif',
  secondary = "#B7A4C6",
  serif = '"Merriweather", serif',
  visuallyHidden = "position: absolute !important; height: 1px; width: 1px; overflow: hidden; clip: rect(1px, 1px, 1px, 1px); white-space: nowrap;",
  white = "#EEEDEE",
  blue = "#356ABE",
  focusBackground = "#FFAB87",
  red = "#CB392A",
  yellow = "#FBF864"
}

// INTERFACES
export interface ICommit {
  content: string | null;
  createdAt: Date;
  createdBy: string;
  id: string;
  idOwner: string;
  idDocument: string;
  message: string;
}

export interface IDocumentContent {
  content: [any] | null;
  idDocument: string;
}

export interface IDocumentMeta {
  createdAt: Date;
  idDocument: string;
  idOwner: string;
  modifiedAt: Date;
  modifiedBy: string;
  name: string;
  public: boolean | false;
  rules: string[];
}

export interface IWidget {
  content: string;
  createdAt: Date;
  id: string;
  idIDocument: string;
  idOwner: string;
  modifiedAt: Date;
  modifiedBy: string;
  rules: string;
  type: string;
}

// export interface IPapyrus {
//   // firebase document
//   commits?: ICommit[]; // firebase collection
//   documentsContent?: IDocumentContent[]; // firebase collection
//   documentsMeta ?: IDocumentMeta[]; // firebase collection
//   name: string;
// }

export interface IUser {
  displayName: string | null;
  photoURL: string | null;
  uid: string | null;
  email: string | null;
  emailVerified: boolean | null;
}
