import React from "react";
import { db } from "./firebase";
import { nanoid } from "nanoid";
import * as types from "../types";

export function createEmptyDocumentContent(documentId: string) {
  let document: types.IDocumentContent = {
    idDocument: documentId,
    content: [
      {
        children: [
          {
            text: "This is editable plain text, just like a <textarea>!"
          }
        ]
      }
    ]
  };

  return document;
}

export function createDocumentMeta({
  userUID,
  documentId
}: {
  userUID: string;
  documentId: string;
}) {
  const documentMeta: types.IDocumentMeta = {
    createdAt: new Date(),
    idDocument: documentId,
    idOwner: userUID,
    modifiedBy: userUID,
    modifiedAt: new Date(),
    name: "Untitled",
    public: false,
    rules: []
  };

  return documentMeta;
}

export function createDocument(userUID: string | null) {
  if (!userUID) return;

  const documentId = nanoid();
  const documentMeta = createDocumentMeta({ userUID, documentId });
  const documentContent = createEmptyDocumentContent(documentId);

  // create content
  db.collection(types.EnumCollection.Papyrus)
    .doc(userUID)
    .collection(types.EnumCollection.Documents)
    .doc(documentId)
    .set(documentContent, { merge: true });

  // create meta
  db.collection(types.EnumCollection.Papyrus)
    .doc(userUID)
    .collection(types.EnumCollection.DocumentsMeta)
    .add(documentMeta);
}

interface IParamCollection {
  path: string;
  orderBy?: string | null;
}

export function useCollection(params: IParamCollection) {
  const { path, orderBy } = params;
  const [collection, setCollection] = React.useState([]);

  React.useEffect(() => {
    let collection = db.collection(path);

    if (orderBy) {
      // @ts-ignore
      collection = collection.orderBy(orderBy);
    }

    return collection.onSnapshot((snapshot: any) => {
      const data: any = [];

      snapshot.forEach((doc: { id: any; data: any }) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setCollection(() => data);
    });
  }, [orderBy, path]);

  return collection;
}
