import React from "react";
import styled from "styled-components";
import { EnumTokens, EnumLoginProviders } from "./types";

interface ISignInButtonProps {
  $background: string;
}

const styles = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    background: ${EnumTokens.background};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    align-items: center;
  `,
  SignIn: styled.div`
    margin-top: 64px;
    background: transparent;
    border: 2px solid ${EnumTokens.border};
    width: 352px;
    font-family: ${EnumTokens.serif};
    color: ${EnumTokens.white};
    border-radius: ${EnumTokens.borderRadius};
    display: flex;
  `,
  SignInText: styled.span`
    flex-basis: 100%;
    flex-grow: 1;
    display: inline-block;
    padding: 8px;
  `,
  SignInButton: styled.button<ISignInButtonProps>`
    height: 100%;
    max-width: 112px;
    flex-basis: 100%;
    border: 0px;
    color: ${EnumTokens.white};
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;

    &:focus,
    &:hover {
      background: ${EnumTokens.focusBackground};
      color: ${EnumTokens.black};
    }

    ${(props: ISignInButtonProps) => {
      const { $background } = props;
      return `background: ${$background}`;
    }}
  `,
  Tag: styled.p`
    margin-top: 16px;
    font-size: 14px;
    color: ${EnumTokens.white};
  `,
  Link: styled.a`
    color: ${EnumTokens.link};
    font-weight: bold;
    display: inline-block;
    padding: 0 3px;

    &:focus,
    &:hover {
      color: ${EnumTokens.focusBackground};
    }
  `,
  Error: styled.p`
    background: ${EnumTokens.white};
    border-radius: ${EnumTokens.borderRadius};
    color: ${EnumTokens.red};
    display: inline-block;
    font-size: 14px;
    max-width: 352px;
    padding: 16px;
  `
};

interface IAuth {
  onAuth: Function;
  hasError: { message: string; code: string } | null;
}

export default function Auth(props: IAuth) {
  const { onAuth, hasError = null } = props;
  const handleClick = (
    provider: EnumLoginProviders.Github | EnumLoginProviders.Google
  ) => () => {
    onAuth(provider);
  };

  return (
    <styles.Container>
      <div>
        <img src="beartha.png" width="153" alt="puking rainbows" />
      </div>
      <styles.SignIn>
        <styles.SignInText>Sign in with:</styles.SignInText>
        <styles.SignInButton
          onClick={handleClick(EnumLoginProviders.Google)}
          $background={EnumTokens.primary}
          type="button"
        >
          <span>Google</span>
        </styles.SignInButton>
        <styles.SignInButton
          onClick={handleClick(EnumLoginProviders.Github)}
          $background={EnumTokens.secondary}
          type="button"
        >
          <span>Github</span>
        </styles.SignInButton>
      </styles.SignIn>
      {hasError ? <styles.Error>{hasError.message}</styles.Error> : null}
      <styles.Tag>
        Creating an ambitious text editor by one colour at a time.{" "}
        <styles.Link href="https://twitter.com/_pukingrainbows" target="_blank">
          @_pukingrainbows
        </styles.Link>
      </styles.Tag>
    </styles.Container>
  );
}
