import React from "react";
import styled from "styled-components";
import * as types from "./types";
import { FaFeather } from "react-icons/fa";
import { createDocument, useCollection } from "./modules/useFirebase";

const styles = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 220px auto;
    grid-template-rows: 44px calc(100vh - 44px);
    width: 100vw;
    height: 100vh;

    grid-template-areas:
      "header header"
      "sidebar content";

    .header {
      grid-area: header;
      background: ${types.EnumTokens.background};
      color: ${types.EnumTokens.white};
      display: flex;
      align-items: center;
      padding: 0 8px;
    }

    .sidebar {
      grid-area: sidebar;
      border-right: 1px solid #f1f1f1;
    }

    .content {
      grid-area: content;
    }
  `,
  Empty: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 46px);
    & .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      max-width: 308px;
    }
  `,
  Message: styled.div`
    background: ${types.EnumTokens.background};
    border-radius: ${types.EnumTokens.borderRadius};
    color: ${types.EnumTokens.white};
    padding: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `,
  Button: styled.button`
    height: 100%;
    background: transparent;
    border: 0;
    color: ${types.EnumTokens.white};
    font-size: 16px;
    span {
      padding-left: 4px;
    }

    &:hover,
    &:focus {
      color: ${types.EnumTokens.yellow};
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
  `
};

interface IPukingRainbows {
  user: types.IUser;
  onLogout: Function;
}

export default function PukingRainbows(props: IPukingRainbows) {
  const { onLogout, user } = props;
  const collection = useCollection({
    path: `${types.EnumCollection.Papyrus}/${user.uid}/${types.EnumCollection.DocumentsMeta}`
  });

  function handleClickCreateDocument() {
    createDocument(user.uid);
  }

  console.log(collection);

  return (
    <styles.Container>
      <styles.Header className="header">
        <div>
          <styles.Button onClick={handleClickCreateDocument}>
            <FaFeather />
            <span>add document</span>
          </styles.Button>
        </div>
        <div>
          <button type="button" onClick={() => onLogout()}>
            sign out
          </button>
        </div>
      </styles.Header>
      <div className="sidebar">
        {collection.map((item: types.IDocumentMeta) => {
          return (
            <div key={item.idDocument}>
              <button>{item.name}</button>
            </div>
          );
        })}
      </div>
      <div className="content">
        <styles.Empty>
          <div className="empty">
            <img src="/bearthaSurprise.png" alt="Beartha surprise" />
            <styles.Message>
              Don’t look at me, go and create your first document on the
              sidebar, have fun!
            </styles.Message>
          </div>
        </styles.Empty>{" "}
      </div>
    </styles.Container>
  );
}
