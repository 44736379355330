import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCxOZQ0datkayyfv82ec2atGa0OVH7V2kI",
  authDomain: "pukingrainbows-67a9b.firebaseapp.com",
  databaseURL: "https://pukingrainbows-67a9b.firebaseio.com",
  projectId: "pukingrainbows-67a9b",
  storageBucket: "pukingrainbows-67a9b.appspot.com",
  messagingSenderId: "182945472272",
  appId: "1:182945472272:web:5a440f97ec7259bb32cf95"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default firebase;
export { db };
