import React from "react";
import { createGlobalStyle } from "styled-components/macro";
import Auth from "./Auth";
import PukingRainbows from "./PukingRainbows";
import { EnumLoginProviders, EnumCollection, IUser } from "./types";
import firebase, { db } from "./modules/firebase";
import consoleWelcome from "./consoleWelcome";

const GlobalCSS = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;

    * {
      box-sizing: border-box;
    }

    font-family: "Lato", sans-serif;
  }

  button, a, [role="button"] {
    cursor: pointer;
  }
`;

interface IError {
  message: string;
  code: string;
}

export function useSignIn() {
  const [user, setUser] = React.useState<firebase.User | null>();
  const [error, setError] = React.useState<IError | null>(null);

  async function handleSignIn(
    provider: EnumLoginProviders.Github | EnumLoginProviders.Google
  ) {
    const providers = {
      [EnumLoginProviders.Github]: () => new firebase.auth.GithubAuthProvider(),
      [EnumLoginProviders.Google]: () => new firebase.auth.GoogleAuthProvider()
    };

    if (provider in providers) {
      const providerToUse = providers[provider]();
      try {
        await firebase.auth().signInWithPopup(providerToUse);
      } catch (e) {
        setError(e);
      }
      return;
    }

    throw new Error(`${provider} useSignIn: is not implemented`);
  }

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(firebaseUser => {
      const _user: IUser = {
        displayName: firebaseUser?.displayName
          ? firebaseUser.displayName
          : null,
        email: firebaseUser?.email ? firebaseUser.email : null,
        emailVerified: firebaseUser?.emailVerified
          ? firebaseUser.emailVerified
          : null,
        photoURL: firebaseUser?.photoURL ? firebaseUser.photoURL : null,
        uid: firebaseUser?.uid ? firebaseUser.uid : null
      };

      if (firebaseUser && firebaseUser.uid) {
        db.collection(`${EnumCollection.Users}`)
          .doc(firebaseUser.uid)
          .set(_user, { merge: true });
        setUser(firebaseUser);
        return;
      }

      if (firebaseUser?.uid === null) {
        setError({
          message:
            "We can't process your request, your account lacks of an uid",
          code: "internal"
        });
        return;
      }

      setUser(null);
    });
  }, []);

  React.useEffect(() => {
    console.log(consoleWelcome);
  }, []);

  return { user, error, handleSignIn };
}

function App() {
  const { user, error, handleSignIn } = useSignIn();

  function handleLogout() {
    firebase.auth().signOut();
  }
  return (
    <>
      <GlobalCSS />
      {user ? (
        <PukingRainbows user={user} onLogout={handleLogout} />
      ) : (
        <Auth onAuth={handleSignIn} hasError={error} />
      )}
    </>
  );
}

export default App;
