export default `                                                          
         _   _                    _     _                 
 ___ _ _| |_|_|___ ___    ___ ___|_|___| |_ ___ _ _ _ ___ 
| . | | | '_| |   | . |  |  _| .'| |   | . | . | | | |_ -|
|  _|___|_,_|_|_|_|_  |  |_| |__,|_|_|_|___|___|_____|___|
|_|               |___|                                   

Designed in Tomb 105 developed close to Sḵwx̱wú7mesh, puked by a mighty Bear and swallowed by a skinny whale. Don't mess with colours!
-- 2020 twitter.com/@_pukingrainbows github.com/pukingrainbows
`;
